.sidebar {
  position: fixed;
  bottom: 0;
  z-index: 99;
  height: 100vh;
  width: 240px;
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: 60px;
  transition: transform 0.3s;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  a {
    display: block;
  }

  &.sidebar--show {
    display: block;

    @include directify($directions) {
      transform:  #{directed('sidebar-close')};
    }

    .sidebar__back {
      display: block;
    }
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__link-active {

  .sidebar__link {

    &:before {
      opacity: 1;
    }
  }

  @include themify($themes) {
    background: themed('sidebarColor');
  }
}

.sidebar__scroll {
  width: 240px;

  & > div {
    height: calc(100vh - 60px);
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;

      @include directify($directions) {
        #{directed('margin-right')}: 3px;
      }
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
  padding-top: 15px;
  height: 100%;
  overflow: auto;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__block {
  padding: 15px 0;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  &:last-child {
    border: none;
  }
}

.sidebar__link {
  height: 36px;
  width: 240px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: $color-accent;
    opacity: 0;
    transition: all 0.3s;

    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }

  p {
    position: absolute;
    width: 180px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%);

    @include directify($directions) {
      #{directed('left')}: 43px;
    }
  }

  &:hover {
    text-decoration: none;

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    &:before {
      opacity: 1;
    }
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.sidebar__link-icon {
  font-size: 13px;
  line-height: 13px;
  color: lighten($color-gray, 25%);

  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {

      @include directify($directions) {
        #{directed('padding-left')}: 53px;
      }

      p {

        @include directify($directions) {
          #{directed('left')}: 53px;
        }
      }
    }
  }

  @include themify($themes) {
    background-color: themed('colorHover');
  }

  .sidebar__link {

    @include directify($directions) {
      #{directed('padding-left')}: 43px;
    }

    &:hover {

      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }
  }

  .sidebar__link {
    display: block;
  }
}

.rtl-support {

  .sidebar__category-icon {
    transform: rotate(180deg);
  }
  .ml-auto, .mx-auto {
    margin-right: auto!important;
    margin-left: 0!important;
  }
}

.sidebar__category-icon {
  position: absolute;
  font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: $color-gray;

  @include directify($directions) {
    #{directed('right')}: 15px;
  }
}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }

  span {
    position: absolute;
    top: 3px;
    width: 26px;
    text-align: center;

    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {

  &.sidebar__category-wrap--open {

    .sidebar__category-icon {
      transform: rotate(90deg);
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  background: $color-red;

  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }
}

@media screen and (min-width: 576px) {
  .sidebar-collapse-wrapper {
    &:hover {

      .sidebar__category {
        width: 240px !important;
      }
      .sidebar__submenu-wrap {
        display: inherit !important;
      }
    }
  }

  .sidebar {

    &.sidebar--no-desktop {

      @include directify($directions) {
        //transform: translateX(calc(directed('-')100% directed('-') 20px));
      }
      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: 55px;
      overflow: visible;
      transition: transform 0.3s, width 0.3s;

      .sidebar__scroll, .sidebar__content {
        width: 55px;
        overflow: visible !important;
        transition: width 0.3s;
      }

      .sidebar__submenu {
        padding: 0 0 15px 0;
        transition: 0s;
      }

      .sidebar__category-wrap {

        &:hover {

          .sidebar__category {
            width: 240px;

            @include themify($themes) {
              background: themed('colorHover');
            }

            &:before {
              opacity: 1;
            }
          }
        }
      }

      .sidebar__submenu-wrap {
        position: absolute;
        width: 0;
        transition: 0.3s;
        display: none;

        @include directify($directions) {
          #{directed('left')}: 55px;
        }

        .sidebar__link {
          width: 185px;

          @include directify($directions) {
            #{directed('padding-left')}: 15px;
          }

          p {
            position: relative;
            animation: none;

            @include directify($directions) {
              #{directed('left')}: 0px;
            }

          }
        }

        .sidebar__submenu-wrap {
          position: relative;

          @include directify($directions) {
            #{directed('left')}: 0px;
          }

          .sidebar__link {

            @include directify($directions) {
              #{directed('padding-left')}: 30px;
            }
          }
        }
      }

      .sidebar__link {
        overflow: hidden;
        width: 55px;
        @include themify($themes) {
          background: themed('colorBackground');
        }

        p {
          position: absolute;
          width: 160px;

          @include directify($directions) {
            #{directed('left')}: 70px;
          }

        }

        &:hover {

          @include themify($themes) {
            background: themed('colorHover');
          }
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }

      .scrollbar-track.scrollbar-track-y {

        @include directify($directions) {
          #{directed('margin-right')}: 188px;
        }
      }
    }
  }

  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }
}

@media (max-width: 575px) {

  .sidebar {
    display: none;
    position: fixed;
    top: 60px;
    padding-top: 0;
    z-index: 101;
  }

  .sidebar__content {
    padding-top: 0;
  }
}

@media screen and (min-width: 1300px) {

  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}

@media screen and (max-width: 1400px) {

  .sidebar.sidebar--no-desktop {
    display: none;
  }

  .sidebar.sidebar--no-desktop.sidebar--show {
    display: block;
  }
}
